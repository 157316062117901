import Form from "react-bootstrap/Form";
import {useContext} from "react";
import AuthContext from "../../../context/AuthProvider";
import log from "../../../Logger";

const DiagSummaryWrite = () => {
    const { setTask, task } = useContext(AuthContext);

    const handleMessageChange = event => {
        setTask({...task, diagSummary: event.target.value})
    };

    const handleFocusIn = event => {
        log('DiagSummaryWrite: (enter to input field)');
    }

    const handleFocusOut = event => {
        log('DiagSummaryWrite: (' + event.target.value  + ' )');
    }
    return (
        <>
            <h3 className={'mt-3'}>Данные обследования</h3>
            <Form.Control as="textarea" rows={3} disabled={true} value={task.mainDiagnos}/>

            <h3 className={'mt-3'}>Сформулируйте диагностическое заключение</h3>
            <Form.Control as="textarea" rows={3} onChange={handleMessageChange} value={task.diagSummary} onFocus={handleFocusIn} onBlur={handleFocusOut}/>
        </>
    );
}

export default DiagSummaryWrite;
