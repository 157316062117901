import {createContext, useContext, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../Config";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [problems, setProblems] = useState({});
    const [auth, setAuth] = useState({});
    const [registrationComplete, setRegistrationComplete] = useState(false);
    const [editId, setEditId] = useState(null);
    const [timeToSkip, setTimeToSkip] = useState(1.5 * 60 * 60 * 1000);
    const [commercial, setCommercial] = useState(false);
    const [hasPaid, setHasPaid] = useState(false);
    const [wrongAnswersToSkip, setWrongAnswersToSkip] = useState(30);

    const [task, setTask] = useState({
        number: 0,
        chapter: 0,
        page: 0,
        lastTask: 1,
        wrongAnswers: 0,
        description: '',
        resumeFenom: ''
    });

    if (auth.user_id &&  auth.user_id != '')
    {
        axios.post(SERVER_URL + '/api/save_state.php',
            {
                user_id: auth.user_id,
                state: task
            }
        );
    }

    return (
        <AuthContext.Provider value={{ auth, setAuth, task, setTask, problems, setProblems, editId, setEditId,
            registrationComplete, setRegistrationComplete, timeToSkip, setTimeToSkip,
            commercial, setCommercial, hasPaid, setHasPaid, wrongAnswersToSkip, setWrongAnswersToSkip }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
