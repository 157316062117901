import {Button, Container, Modal, Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../Config";
import GroupReport from "../reports/GroupReport";
import GroupAverageReport from "../reports/GroupAverageReport";
import GroupAverageByProblem from "../reports/GroupAverageByProblem";

const AdminProtocols = () => {

    const [loaded, setLoaded] = useState(false);

    const [dataGroupedByInvite, setDataGroupedByInvite] = useState([]);
    const [viewInvite, setViewInvite] = useState(null);
    const [show, setShow] = useState(false);

    const [viewGroupProtocol, setViewGroupProtocol] = useState(null);
    const [viewGroupAverageProtocol, setViewGroupAverageProtocol] = useState(null);
    const [viewGroupAverageByProblem, setViewGroupAverageByProblem] = useState(null);


    async function fetchData() {
        const response = await axios.get(SERVER_URL + '/api/users.php');

        response.data.forEach(user => {
            let group = dataGroupedByInvite[user.invite_id];
            if (group) {
                group.push(user);
            }
            else
            {
                dataGroupedByInvite[user.invite_id] = [];
                group = dataGroupedByInvite[user.invite_id];
                group.push(user);
            }
        });

        setDataGroupedByInvite(dataGroupedByInvite);
        setLoaded(true);
    }
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {
                !loaded && <Container>&nbsp;</Container>
            }
            {viewGroupAverageByProblem != null &&
                <GroupAverageByProblem group={viewGroupAverageByProblem}></GroupAverageByProblem>
            }
            {viewGroupAverageProtocol != null &&
                <GroupAverageReport group={viewGroupAverageProtocol}></GroupAverageReport>
            }
            {viewGroupProtocol != null &&
                <GroupReport group={viewGroupProtocol}></GroupReport>
            }
            {
                viewGroupProtocol == null &&
                viewGroupAverageProtocol == null &&
                viewGroupAverageByProblem == null &&
                <>
                {viewInvite ?
                    <>
                            <Container>
                            {dataGroupedByInvite[viewInvite][0].invite === '' ?
                                <h2>{'Без приглашения'}</h2>
                                :
                                <h2>{dataGroupedByInvite[viewInvite][0].invite}&nbsp;-&nbsp;{dataGroupedByInvite[viewInvite][0].invites_name === '' ? 'Без названия' : dataGroupedByInvite[viewInvite][0].invites_name}</h2>
                            }
                                <Button className={'btn-secondary'} onClick={() => setViewGroupProtocol(dataGroupedByInvite[viewInvite])} title={"Индивидуальные показатели диагностического процесса по каждой задаче"}>Индивидуальные показатели</Button>
                                &nbsp;
                                <Button className={'btn-secondary'} onClick={() => setViewGroupAverageProtocol(dataGroupedByInvite[viewInvite])} title={"Индивидуальные средние по этапам диагностического процесса по каждому студенту"}>Индивидуальные средние</Button>
                                &nbsp;
                                <Button className={'btn-secondary'} onClick={() => setViewGroupAverageByProblem(dataGroupedByInvite[viewInvite])} title={"Групповые средние показатели по каждой задаче"}>Групповые средние</Button>
                            </Container>
                            <Table bordered hover>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Email</th>
                                    <th>ФИО</th>
                                    <th>Протокол</th>
                                </tr>
                                </thead>
                                <tbody>
                                { dataGroupedByInvite[viewInvite].map(item =>
                                    <tr>
                                        <td>{item.id}</td>
                                        <td>{item.email}</td>
                                        <td>{item.fio}</td>
                                        <td>
                                            <a className={'btn btn-secondary'} href={"/?report_short=" + item.user_token} target={"_blank"}>Краткий</a>
                                            &nbsp;
                                            <a className={'btn btn-secondary'} href={"/?report_moves=" + item.user_token} target={"_blank"}>Гуляние</a>
                                            &nbsp;
                                            <a className={'btn btn-secondary'} href={"/?report_methods=" + item.user_token} target={"_blank"}>Методики</a>
                                            &nbsp;
                                            <a className={'btn btn-secondary'} href={"/?report_full=" + item.user_token} target={"_blank"}>Полный</a>
                                            &nbsp;
                                            <a className={'btn btn-secondary'} href={"/?report_int=" + item.user_token} target={"_blank"}>Интегральный</a>
                                            &nbsp;
                                            <a className={'btn btn-secondary'} href={"/?report_charts=" + item.user_token} target={"_blank"}>Графики</a>
                                            &nbsp;
                                            <a className={'btn btn-secondary'} href={"/?report_allcharts=" + item.user_token} target={"_blank"}>Все задачи</a>
                                        </td>
                                    </tr>
                                ) }
                                </tbody>
                            </Table>
                            <Container><Button className={'btn-danger'} onClick={() => {setShow(true);}}>Разослать письма с протоколами</Button></Container>
                        </>
                        :
                        <Container>
                            <h1>Протоколы по приглашениям</h1>
                            <Container>
                                {
                                    dataGroupedByInvite.map((item, index) =>
                                    {
                                        return <Container className={'text-start'}>
                                            {item[0].invite === '' ?
                                                <></>
                                                :
                                                <a href={'#'}
                                                   onClick={() => setViewInvite(item[0].invite_id)}>{item[0].invite}&nbsp;-&nbsp;{item[0].invites_name === '' ? 'Без названия' : item[0].invites_name}</a>
                                            }
                                        </Container>;
                                    })
                                }
                            </Container>
                        </Container>
                    }
                </>
            }
            <Modal
                show={show}
                onHide={() => {setShow(false);}}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Подтвердите отправку писем</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Внимание! Не стоит слишком часто отправлять письма с протоколами. Это будет засорять почтовые ящики пользователей и будет повышать попадание их в папку СПАМ.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        const invite_id = dataGroupedByInvite[viewInvite][0].invite_id;
                        axios.get(SERVER_URL + '/api/send_protocols.php',
                            { params:{
                                    invite_id
                                }
                            }
                        );
                        setShow(false);
                    }}>Разослать письма</Button>
                    <Button variant="secondary" onClick={() => {setShow(false);}}>
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AdminProtocols
