import {Button, Container} from "react-bootstrap";
import {useContext} from "react";
import AuthContext from "../../context/AuthProvider";
import Diagnos from "./diagnos/Diagnos";
import log from "../../Logger";

const MainDiagnos = () => {
    const { setTask, task } = useContext(AuthContext);

    return (
        <>
               {
                    task && task.number === 1 ?<>
                        <Diagnos></Diagnos>
                        <Container className={'my-4'}>
                            <Button className={'float-start'} onClick={() => {
                                log('MainDiagnos, click back');
                                setTask({...task, chapter: 0, number: 1})
                            }}>Назад</Button>
                            <Button className={'align-middle'} onClick={() => {
                                log('MainDiagnos, click main menu');
                                setTask({...task, chapter: 0, number: 1})
                            }}>Главное меню</Button>
                            <Button className={'float-end'} onClick={() => {
                                log('MainDiagnos, click apply');
                                setTask({...task, chapter: 0, number: 1})
                            }}>Принять</Button>
                        </Container>
                    </>: ''
                }
        </>
    );
}

export default MainDiagnos
