import {Button, Container, Form, Modal, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import AuthContext from "./context/AuthProvider";
import log from "./Logger";
import axios from "axios";
import {SERVER_URL} from "./Config";

const Navigation = (props) => {
    const { auth, task, setTask, problems, commercial, hasPaid} = useContext(AuthContext);

    const [ showWishes, setShowWishes] = useState(false);
    const [ wishes1, setWishes1 ] = useState('');
    const [ wishes2, setWishes2 ] = useState('');
    const [ wishes3, setWishes3 ] = useState('');
    const [ wishes4, setWishes4 ] = useState('');
    const [ wishes5, setWishes5 ] = useState('');
    const [ wishes6, setWishes6 ] = useState('');


    const [ survey, setSurvey ] = useState([0,0,0,0,0,0,0,0]);
    const [ showSurvey, setShowSetSurvey ] = useState(false);

    async function sendWishes()
    {
        await axios.post(SERVER_URL + '/api/wishes.php',
            {
                wishes1,
                wishes2,
                wishes3,
                wishes4,
                wishes5,
                wishes6,
                user_id: auth.user_id,
            }
        );
        setWishes1('');
        setWishes2('');
        setWishes3('');
        setWishes4('');
        setWishes5('');
        setShowWishes(false);
    }

    useEffect(()=>{
        if (commercial) {
            return;
        }

        const now = new Date();
        const end = new Date(auth.invite_end);


        let timer = null;
        if (end - now > 15 * 60 * 1000)
        {
            timer = setTimeout(() => setShowSetSurvey(true), 15 * 60 * 1000);
        }

        const timeToLastSurvey = end - now - 15 * 60 * 1000;
        let timer2 = null;
        if (timeToLastSurvey > 0) {
             timer2 = setTimeout(()=>setShowSetSurvey(true), timeToLastSurvey);
        }

        return () => {
            if (timer != null)
            {
                clearTimeout(timer);
            }

            if (timer2 != null)
            {
                clearTimeout(timer2);
            }
        }
    }, []);

    async function sendSurvey ()
    {
        await axios.post(SERVER_URL + '/api/survey.php',
            {
                user_id: auth.user_id,
                problem_id: task.id? task.id: -1,
                survey,
                state: {
                    auth,
                    task
                }
            }
        );
        setShowSetSurvey(false);
        setSurvey([0,0,0,0,0,0,0,0]);
    }

    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand href="#" onClick={() => { log('Click to psycase.ru'); setTask({...task, page: 0})} } active={task.page == 0}>psycase.ru</Navbar.Brand>
                <Nav className="d-flex">
                    <Nav.Item>
                        <Nav.Link href="#" onClick={() => { log('Click to Instructions'); setTask({...task, page: 0})} } active={task.page == 0}>Инструкция</Nav.Link>
                    </Nav.Item>
                    <NavDropdown menuVariant="dark" title={"Задача " + (task.page? task.page:'')} id="nav-dropdown" active={task.page != 0}>
                        {
                            problems.map((item, index) =>
                            {
                                if (item.free === '1' || hasPaid){
                                    return <NavDropdown.Item eventKey={index}
                                                             onClick={() => {
                                                                 log('Click to navigation ' + (index + 1));

                                                                 setTask({
                                                                     ...task,
                                                                     id: item.id,
                                                                     page: index + 1,
                                                                     chapter: 0,
                                                                     number: 1
                                                                 });
                                                             }}>Задача {index + 1}</NavDropdown.Item>
                                } else if (!commercial) {
                                    return <NavDropdown.Item eventKey={index} className={task && (index < task.lastTask - 1 || task.lessonDone) ? 'green-nav': ''}
                                                      onClick={() => {
                                                          log('Click to navigation ');
                                                          if (!task.page || task.page === 0)
                                                          {
                                                              setTask({
                                                                  ...task,
                                                                  id: problems[task.lastTask - 1].id,
                                                                  page: task.lastTask,
                                                                  chapter: 0,
                                                                  number: 1
                                                              });
                                                          }
                                                      }}>
                                            Задача {index + 1}
                                            &nbsp;
                                            {(task && (index < task.lastTask - 1 || task.lessonDone))  &&
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                    <path
                                                        d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                                </svg>
                                            }
                                    </NavDropdown.Item>;
                                }
                                else
                                {
                                    return <NavDropdown.Item eventKey={index}
                                                             onClick={() => {
                                                             }}>Задача {index + 1} (Доступно будет после покупки)</NavDropdown.Item>
                                }
                            })
                        }
                    </NavDropdown>
                    <Nav.Item>
                        <Nav.Link href="#survey" onClick={() => {
                            log('Open survey');
                            setShowSetSurvey(true);
                        }} active={false}>Опрос</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link href="#wishes" onClick={() => {
                            log('Open wishes');
                            setShowWishes(true);
                        }} active={false}>Пожелания</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#2" onClick={() =>{ log('Click to Logout');  window.location.reload()}}>Выйти</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Container>

            <Modal
                show={showWishes}
                onHide={() => {
                    log('Cancel wishes');
                    setShowWishes(false);
                }}
                backdrop="static"
                keyboard={false}
                size={'lg'}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Напишите Ваши пожелания</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Опишите свое состояние после решения</h4>
                    <Form.Group className="mb-3">
                        <Form.Control as="textarea" rows={3} onChange={event => {
                            setWishes1(event.target.value);
                        }} value={wishes1} />
                    </Form.Group>
                    <h4>Что понравилось?</h4>
                    <Form.Group className="mb-3">
                        <Form.Control as="textarea" rows={3} onChange={event => {
                            setWishes2(event.target.value);
                        }} value={wishes2} />
                    </Form.Group>
                    <h4>Что не понравилось?</h4>
                    <Form.Group className="mb-3">
                        <Form.Control as="textarea" rows={3} onChange={event => {
                            setWishes3(event.target.value);
                        }} value={wishes3} />
                    </Form.Group>
                    <h4>Что вызвало затруднения?</h4>
                    <Form.Group className="mb-3">
                        <Form.Control as="textarea" rows={3} onChange={event => {
                            setWishes4(event.target.value);
                        }} value={wishes4} />
                    </Form.Group>
                    <h4>Что бы Вы улучшили в программе?</h4>
                    <Form.Group className="mb-3">
                        <Form.Control as="textarea" rows={3} onChange={event => {
                            setWishes5(event.target.value);
                        }} value={wishes5} />
                    </Form.Group>
                    <h4>Какие способы решения задач вы использовали?</h4>
                    <Form.Group className="mb-3">
                        <Form.Control as="textarea" rows={3} onChange={event => {
                            setWishes6(event.target.value);
                        }} value={wishes6} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={sendWishes}>Отправить</Button>
                    <Button variant="secondary" onClick={() => {
                        log('Cancel wishes');
                        setShowWishes(false);
                    }}>
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showSurvey}
                onHide={() => {
                    log('Cancel survey');
                    setShowSetSurvey(false);
                    setSurvey([0,0,0,0,0,0,0,0]);
                }}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ответьте на вопросы</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className={'text-start'}>
                        <Form.Check
                            defaultChecked={survey[0] == 1}
                            type={'checkbox'}
                            label={'Посильность поставленной перед собой задачи'}
                            onChange={(e) =>
                            {
                                const newSurvey = [...survey];
                                newSurvey[0] = e.target.checked ? 1 : 0;
                                setSurvey(newSurvey)
                            }}
                        />
                    </Container>
                    <Container className={'text-start'}>
                        <Form.Check
                            defaultChecked={survey[1] == 1}
                            type={'checkbox'}
                            label={'Возможность сосредоточения'}
                            onChange={(e) =>
                            {
                                const newSurvey = [...survey];
                                newSurvey[1] = e.target.checked ? 1 : 0;
                                setSurvey(newSurvey)
                            }}
                        />
                    </Container>
                    <Container className={'text-start'}>
                        <Form.Check
                            defaultChecked={survey[2] == 1}
                            type={'checkbox'}
                            label={'Четкая формулировка цели'}
                            onChange={(e) =>
                            {
                                const newSurvey = [...survey];
                                newSurvey[2] = e.target.checked ? 1 : 0;
                                setSurvey(newSurvey)
                            }}
                        />
                    </Container>
                    <Container className={'text-start'}>
                        <Form.Check
                            defaultChecked={survey[3] == 1}
                            type={'checkbox'}
                            label={'Немедленная обратная связь'}
                            onChange={(e) =>
                            {
                                const newSurvey = [...survey];
                                newSurvey[3] = e.target.checked ? 1 : 0;
                                setSurvey(newSurvey)
                            }}
                        />
                    </Container>
                    <Container className={'text-start'}>
                        <Form.Check
                            defaultChecked={survey[4] == 1}
                            type={'checkbox'}
                            label={'Высокая увлеченность, приводящая к забыванию повседневных тревог и забот'}
                            onChange={(e) =>
                            {
                                const newSurvey = [...survey];
                                newSurvey[4] = e.target.checked ? 1 : 0;
                                setSurvey(newSurvey)
                            }}
                        />
                    </Container>
                    <Container className={'text-start'}>
                        <Form.Check
                            defaultChecked={survey[5] == 1}
                            type={'checkbox'}
                            label={'Ощущение контроля над своими действиями'}
                            onChange={(e) =>
                            {
                                const newSurvey = [...survey];
                                newSurvey[5] = e.target.checked ? 1 : 0;
                                setSurvey(newSurvey)
                            }}
                        />
                    </Container>
                    <Container className={'text-start'}>
                        <Form.Check
                            defaultChecked={survey[6] == 1}
                            type={'checkbox'}
                            label={'Исчезновение своего Я в момент совершения действия'}
                            onChange={(e) =>
                            {
                                const newSurvey = [...survey];
                                newSurvey[6] = e.target.checked ? 1 : 0;
                                setSurvey(newSurvey)
                            }}
                        />
                    </Container>
                    <Container className={'text-start'}>
                        <Form.Check
                            defaultChecked={survey[7] == 1}
                            type={'checkbox'}
                            label={'Изменение восприятия времени'}
                            onChange={(e) =>
                            {
                                const newSurvey = [...survey];
                                newSurvey[7] = e.target.checked ? 1 : 0;
                                setSurvey(newSurvey)
                            }}
                        />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={sendSurvey}>Отправить</Button>
                    <Button variant="secondary" onClick={() => {
                        log('Cancel survey');
                        setShowSetSurvey(false);
                        setSurvey([0,0,0,0,0,0,0,0]);
                    }}>
                        Отмена
                    </Button>
                </Modal.Footer>
            </Modal>

        </Navbar>
    );
}

export default Navigation
