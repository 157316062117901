import {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../Config";
import {Button, Container, Table} from "react-bootstrap";
import AnalyticsCharts from "../../analytics/AnalyticsCharts";
import LineChart from "../LineChart";
import AnalyticsHypos from "../../analytics/AnalyticsHypos";
import AnalyticsMethods from "../../analytics/AnalyticsMethods";
import AnalyticsDiag from "../../analytics/AnalyticsDiag";
import AnalyticsProblems from "../../analytics/AnalyticsProblems";

const ChartsReport = (props) => {
    const [data, setData] = useState([]);

    const anlyticsData = new AnalyticsCharts();
    const anlyticsHypos = new AnalyticsHypos();
    const anlyticsProblems = new AnalyticsProblems();
    const anlyticsMethods = new AnalyticsMethods();
    const anlyticsDiag = new AnalyticsDiag();
    const [diag, setDiag] = useState([]);
    const [methods, setMethods] = useState([]);
    const [hypos, setHypos] = useState([]);
    const [selectedProblems, setSelectedProblems] = useState([]);
    const [problems, setProblems] = useState([]);
    const [inviteName, setInviteName] = useState('');

    async function fetchFenom() {
        const response = await axios.get(SERVER_URL + '/api/viewReport.php',
            { params: { token:props.id, user_id:props.user_id } }
        );
        setInviteName(response.data.invite_name);
        setData(response.data);

        anlyticsData.fetch(response.data);
        setProblems(anlyticsData.getProblems());

        anlyticsHypos.fetch(response.data);
        setHypos(anlyticsHypos.getProblems());

        anlyticsProblems.fetch(response.data);
        setSelectedProblems(anlyticsProblems.getProblems());

        anlyticsMethods.fetch(response.data);
        setMethods(anlyticsMethods.getProblems());

        anlyticsDiag.fetch(response.data);
        setDiag(anlyticsDiag.getProblems());
    }
    useEffect(() => {
        fetchFenom();
    }, []);

    function convertToTaskName(page)
    {
        const task = data.invite_order.find(item => item.problem_id == page);
        return ' ' + page + '(' + task.id + ')'+ task.title;
    }

    return (
        <>
            {problems.length === 0 ? <Container><h2>Подготовка протокола...</h2></Container> :
                <Container>
                    <Container><Button onClick={()=>window.close()}>Закрыть</Button></Container>
                    <Container>
                        <Container><h3>Ваш протокол психодиагностических задач</h3></Container>
                        <Container className={'text-start'}>
                            <Container>{inviteName} – {data.fio}</Container>
                            <Container>Дата заполнения начала прохождения – {data.login_time}</Container>
                        </Container>
                    </Container>

                    <Container>
                        <h4>Кривая этапов на общих их временных показателей </h4>
                        {problems.length > 0 && problems.map(prob =>
                            {
                                return <Container><LineChart events={prob.events} page={prob.page}></LineChart></Container>;
                            })
                        }
                    </Container>
                    <Container>
                        <h4>Динамика кол-ва гипотез</h4>
                        {hypos.length > 0 && hypos.map(prob =>
                            {
                                return <Container><LineChart valueEvents={prob.events} page={prob.page}></LineChart></Container>;
                            })
                        }
                    </Container>
                    <Container>
                        <h4>Количесто сформулированных проблем</h4>
                        {selectedProblems.length > 0 && selectedProblems.map(prob =>
                        {
                            return <Container><LineChart valueEvents={prob.events} page={prob.page}></LineChart></Container>;
                        })
                        }
                    </Container>
                    <Container>
                        <h4>Динамика кол-ва причин в диагнозе</h4>
                        {diag.length > 0 && diag.map(prob =>
                        {
                            return <Container><LineChart valueEvents={prob.events} page={prob.page}></LineChart></Container>;
                        })
                        }
                    </Container>
                    <Container>
                        <h4>Динамика кол-ва методик</h4>
                        {methods.length > 0 && methods.map(prob =>
                            {
                                return <Container><LineChart valueEvents={prob.events} page={prob.page} numbers={true}></LineChart></Container>;
                            })
                        }
                    </Container>

                    {/*
                <Container className={'mt-4'}>
                    <Container><h2>Технический журнал</h2></Container>
                    <Container>
                        <Table bordered hover>
                            <thead>
                            <th>Событие</th>
                            <th>Время</th>
                            </thead>
                            <tbody>
                        {data.events && data.events
                            //.filter(item => item.message.indexOf('open task:') < 0)
                            .map((item, index) => {
                                return <tr>
                                        <td className={'col-8 text-start'}>{item.timestamp}</td>
                                        <td className={'col-4 text-start'}>{item.message}</td>
                                </tr>
                            })}
                            </tbody>
                        </Table>
                    </Container>
                </Container>
                    */}

                </Container>
            }
        </>
    );
}

export default ChartsReport
