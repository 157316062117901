import {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../Config";
import {Button, Container} from "react-bootstrap";
import LineChart from "../LineChart";
import AnalyticsAllCharts from "../../analytics/AnalyticsAllCharts";

const AllChartsReport = (props) => {
    const [data, setData] = useState([]);

    const anlyticsData = new AnalyticsAllCharts();
    const [problems, setProblems] = useState(null);
    const [fenom, setFenom] = useState(null);
    const [psyProblem, setPsyProblem] = useState(null);
    const [hypos, setHypos] = useState(null);
    const [diag, setDiag] = useState(null);
    const [diagnos, setDiagnos] = useState(null);
    const [methodsRead, setMethodsRead] = useState(null);
    const [attempts, setAttempts] = useState(null);
    const [countHypos, setCountHypos] = useState(null);

    const [inviteName, setInviteName] = useState('');

    async function fetchFenom() {
        const response = await axios.get(SERVER_URL + '/api/viewReport.php',
            { params: { token:props.id, user_id:props.user_id } }
        );
        setInviteName(response.data.invite_name);
        setData(response.data);

        anlyticsData.fetch(response.data);
        const probl = anlyticsData.getProblems();

        const allTime = {
            events: [
            ]
        };
        probl.forEach(item => {
            allTime.events.push({name:item.page, time:item.events[0].time});
        });
        setProblems(allTime);

        const fenomTime = {
            events: [
            ]
        };
        probl.forEach(item => {
            fenomTime.events.push({name:item.page, time:item.events[1].time});
        });
        setFenom(fenomTime);

        const psyProblemTime = {
            events: [
            ]
        };
        probl.forEach(item => {
            psyProblemTime.events.push({name:item.page, time:item.events[2].time});
        });
        setPsyProblem(psyProblemTime);

        const hyposTime = {
            events: [
            ]
        };
        probl.forEach(item => {
            hyposTime.events.push({name:item.page, time:item.events[3].time});
        });
        setHypos(hyposTime);

        const diagTime = {
            events: [
            ]
        };
        probl.forEach(item => {
            diagTime.events.push({name:item.page, time:item.events[4].time});
        });
        setDiag(diagTime);

        const diagnosTime = {
            events: [
            ]
        };
        probl.forEach(item => {
            diagnosTime.events.push({name:item.page, time:item.events[5].time});
        });
        setDiagnos(diagnosTime);

        const methodRead = {
            events: [
            ]
        };
        probl.forEach(item => {
            methodRead.events.push({name:item.page, value:item.events[6].value});
        });
        setMethodsRead(methodRead);

        const attemptsEvents = {
            events: [
            ]
        };
        probl.forEach(item => {
            attemptsEvents.events.push({name:item.page, value:item.events[7].value});
        });
        setAttempts(attemptsEvents);

        const countHyposEvents = {
            events: [
            ]
        };
        probl.forEach(item => {
            countHyposEvents.events.push({name:item.page, value:item.events[9].value});
        });
        setCountHypos(countHyposEvents);
    }
    useEffect(() => {
        fetchFenom();
    }, []);

    return (
        <>
            {!problems ? <Container><h2>Подготовка протокола...</h2></Container> :
                <Container>
                    <Container><Button onClick={()=>window.close()}>Закрыть</Button></Container>
                    <Container>
                        <Container><h3>Ваш протокол психодиагностических задач</h3></Container>
                        <Container className={'text-start'}>
                            <Container>{inviteName} – {data.fio}</Container>
                            <Container>Дата заполнения начала прохождения – {data.login_time}</Container>
                        </Container>
                    </Container>

                    <h3>Общие временные кривые</h3>
                    <Container>
                        <h4>Время решения каждой задачи(в мин)</h4>
                        <Container><LineChart events={problems.events}
                                              page={problems.page}></LineChart></Container>
                    </Container>
                    {/*
                    <Container>
                        <h1>Время решения задачи после постановки диагноза</h1>
                        <Container><LineChart events={problems.events}
                                              page={problems.page}></LineChart></Container>
                    </Container>
                    */}
                    <h3>Суммарные временные показатели по диагностическим этапам</h3>
                    <Container>
                        <h4>Время работы на этапе феноменологии</h4>
                        <Container><LineChart events={fenom.events}
                                              page={fenom.page}></LineChart></Container>
                    </Container>

                    <Container>
                        <h4>Время работы на этапе выдвижения проблемы</h4>
                        <Container><LineChart events={psyProblem.events}
                                              page={psyProblem.page}></LineChart></Container>
                    </Container>

                    <Container>
                        <h4>Время работы на этапе выдвижения гипотез</h4>
                        <Container><LineChart events={hypos.events}
                                              page={hypos.page}></LineChart></Container>
                    </Container>

                    <Container>
                        <h4>Время работы на этапе данные диагностического обследования</h4>
                        <Container><LineChart events={diag.events}
                                              page={diag.page}></LineChart></Container>
                    </Container>

                    <Container>
                        <h4>Время работы на этапе постановки диагноза</h4>
                        <Container><LineChart events={diagnos.events}
                                              page={diagnos.page}></LineChart></Container>
                    </Container>

                    <h3>Количественные показатели по всем задачам</h3>
                    <Container>
                        <h4>Количество просмотренных методик</h4>
                        <Container><LineChart valueEvents={methodsRead.events}
                                              page={methodsRead.page}></LineChart></Container>
                    </Container>

                    <Container>
                        <h4>Число попыток поставить верный диагноз</h4>
                        <Container><LineChart valueEvents={attempts.events}
                                              page={attempts.page}></LineChart></Container>
                    </Container>

                    <Container>
                        <h4>Количество гипотез</h4>
                        <Container><LineChart valueEvents={countHypos.events}
                                              page={countHypos.page}></LineChart></Container>
                    </Container>

                </Container>
            }
        </>
    );
}

export default AllChartsReport
