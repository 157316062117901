import {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../Config";
import {Button, Container, Table} from "react-bootstrap";
import AnalyticsFull from "../../analytics/AnalyticsFull";

const FullReport = (props) => {
    const [data, setData] = useState([]);

    const anlyticsData = new AnalyticsFull();
    const [problems, setProblems] = useState([]);
    const [inviteName, setInviteName] = useState('');

    function toTimeString(time) {
        if (time < 1000)
        {
            return '0';
        }

        if (time === null || time === undefined)
        {
            return '';
        }

        if (time > 60 * 60* 1000)
        {
            const hours = Math.floor(time/(60 * 60 * 1000));
            const minutes = Math.floor( (time - hours * (60 * 60 * 1000)) / 60000);
            return hours + ' ч ' + minutes + ' м';
        }
        else
        {
            return (Math.round(time/60/100)/10 + ' мин' + ' (' + Math.round(time/1000) + ' сек)').toString().replaceAll('.',',');
        }
    }

    async function fetchFenom() {
        const response = await axios.get(SERVER_URL + '/api/viewReport.php',
            { params: { token:props.id, user_id:props.user_id } }
        );
        setInviteName(response.data.invite_name);
        setData(response.data);
        anlyticsData.fetch(response.data);
        setProblems(anlyticsData.getProblems());
    }
    useEffect(() => {
        fetchFenom();
    }, []);

    function convertToTaskName(page)
    {
        const task = data.invite_order.find(item => item.problem_id == page);
        return 'Задача ' + page + '(' + task.id + ')'+ ' ' + task.title;
    }

    return (
        <>
            {problems.length === 0 ? <Container><h2>Подготовка протокола...</h2></Container> :
                <Container>
                    <Container><Button onClick={()=>window.close()}>Закрыть</Button></Container>
                    <Container>
                        <Container><h3>Ваш протокол психодиагностических задач</h3></Container>
                        <Container className={'text-start'}>
                            <Container>{inviteName} – {data.fio}</Container>
                            <Container>Дата заполнения начала прохождения – {data.login_time}</Container>
                        </Container>
                    </Container>

                    {problems.length > 0 && problems.map(prob =>
                    {
                        return <>
                            <Container className={'text-start mt-3'}>
                                <Container className={'row'}>
                                    <Container className={'text-start'}><h5>{convertToTaskName(prob.page)}</h5></Container>
                                </Container>
                            </Container>
                            {prob.events && prob.events.map(item =>
                            {
                                return <Table bordered hover>
                                    <thead>
                                    <th>{item.name}</th>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={'col-8 text-start'}>
                                            {
                                                item.time && <div>
                                                    Общее время на этапе - {toTimeString(item.time)}
                                                </div>
                                            }
                                            {
                                                item.value && <div>
                                                    {item.value}
                                                </div>
                                            }
                                        </td>
                                    </tr>

                                    {(item.read && item.read.length > 0) && <>
                                        <tr>
                                            <td className={'col-8 text-start'}>
                                                Посмотрел:
                                            </td>
                                        </tr>

                                        {item.read.map(title => <tr className={'text-start'}><td>{title}</td></tr>)}
                                        </>
                                    }

                                    {item.totalFormulationTime != undefined && <>
                                            {
                                                item.totalFormulationTime != 0 &&
                                                    <tr>
                                                        <td className={'col-8 text-start'}>
                                                            Формулировка:&nbsp;
                                                            {toTimeString(item.totalFormulationTime)}
                                                        </td>
                                                    </tr>
                                            }
                                        </>
                                    }

                                    {
                                        item.time > 0 &&
                                        <>
                                            {item.totalFormulationTime > 0 &&
                                            <tr>
                                                <td className={'col-8 text-start'}>
                                                    Уточнение:&nbsp;
                                                    {toTimeString(item.time - item.totalFormulationTime)}
                                                </td>
                                            </tr>
                                            }
                                        </>
                                    }

                                    {item.entered &&
                                        <tr>
                                            <td className={'col-8 text-start'}>
                                                Записанная информация:
                                                <p>{item.entered.replaceAll('u0022', '"').replaceAll('u0027', "'")}</p>
                                            </td>
                                        </tr>
                                    }

                                    {(item.selected && item.selected.length > 0) && <>
                                        <tr>
                                            <td className={'col-8 text-start'}>
                                                Уточнение записанных формулировок:
                                                {item.selected.map(title =><p>{title}</p>)}
                                            </td>
                                        </tr>
                                    </>
                                    }

                                    {(item.methods && item.methods.length > 0) && <>
                                        <tr>
                                            <td className={'col-8 text-start'}>
                                                Просмотр методик:
                                                {item.methods.map(title => <p>{title}</p>)}
                                            </td>
                                        </tr>

                                    </>
                                    }
                                    </tbody>
                                </Table>
                            })}
                        </>
                    })
                    }


                    {/*
                        <Container className={'mt-4'}>
                            <Container><h2>Технический журнал</h2></Container>
                            <Container>
                                <Table bordered hover>
                                    <thead>
                                    <th>Событие</th>
                                    <th>Время</th>
                                    </thead>
                                    <tbody>
                                    {data.events && data.events
                                        //.filter(item => item.message.indexOf('open task:') < 0)
                                        .map((item, index) => {
                                            return <tr>
                                                <td className={'col-8 text-start'}>{item.timestamp}</td>
                                                <td className={'col-4 text-start'}>{item.message}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </Container>
                        </Container>
                    */}
                </Container>
            }</>
    );
}

export default FullReport
