import {Button, Container, InputGroup, Form} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {SERVER_URL} from "../../Config";
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css";
import AuthContext from "../../context/AuthProvider";
import AdminEditTaskOrder from "./AdminEditTaskOrder";

const AdminInvite = (props) => {
    const { problems, auth } = useContext(AuthContext);

    const [startDate, setStartDate] = useState(new Date());

    const today = new Date();
    const tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)
    const [endDate, setEndDate] = useState(tomorrow);

    const [loaded, setLoaded] = useState(false);
    const [inviteData, setInviteData] = useState([]);
    const [days, setDays] = useState(7);
    const [name, setName] = useState('');
    const [commercial, setCommercial] = useState('0');
    const [editItem, setEditItem] = useState(null);
    const [editOrder, setEditOrder] = useState(null);
    const [selectedProblems, setSelectedProblems] = useState([]);

    async function fetchData() {
        const response = await axios.post(SERVER_URL + '/api/invites.php',
            {
                user: auth.user
            }
        );
        setInviteData(response.data);
        setLoaded(true);
    }
    useEffect(() => {
        fetchData();
    }, []);

    async function onNewInvite() {
        const start = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate() + ' ' + startDate.getHours() + ':' + startDate.getMinutes();
        const end = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate() + ' ' + endDate.getHours() + ':' + endDate.getMinutes();
        await axios.post(SERVER_URL + '/api/invites.php', {
             "user": auth.user,
             "new": 1, start, end, name, commercial, selectedProblems
        });
        const response = await axios.post(SERVER_URL + '/api/invites.php',
            {
                user: auth.user
            }
        );
        setInviteData(response.data);
        setName('');
        setSelectedProblems([]);
        setCommercial('0');
        window.scrollTo(0, 0);
    }

    function toClipBoard(inv) {
        navigator.clipboard.writeText('https://psycase.ru/?' + inv);
    }

    async function onDisact(inv) {
        await axios.post(SERVER_URL + '/api/invites.php', {
            "disact": inv
        });
        const response = await axios.post(SERVER_URL + '/api/invites.php', {
            user: auth.user
        });
        setInviteData(response.data);
    }

    async function onAct(inv) {
        await axios.post(SERVER_URL + '/api/invites.php', {
            "act": inv
        });
        const response = await axios.post(SERVER_URL + '/api/invites.php',{
            user: auth.user
        });
        setInviteData(response.data);
    }

    async function onDelete(inv) {
        await axios.post(SERVER_URL + '/api/invites.php', {
             "delete": inv
        });
        const response = await axios.post(SERVER_URL + '/api/invites.php',{
            user: auth.user
        });
        setInviteData(response.data);
    }

    function onStartEdit(item) {
        setEditItem(item);
        setName(item.name);
        setStartDate(new Date(item.start));
        setEndDate(new Date(item.end));
        setCommercial(item.commercial);

        setSelectedProblems(item.problems.map(item => item.id));
    }

    async function onEditInvite() {
        if (!editItem) {
            return;
        }
        const start = startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate() + ' ' + startDate.getHours() + ':' + startDate.getMinutes();
        const end = endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate() + ' ' + endDate.getHours() + ':' + endDate.getMinutes();
        await axios.post(SERVER_URL + '/api/invites.php', {
             "edit": editItem.invite, start, end, name, commercial, selectedProblems
        });
        const response = await axios.post(SERVER_URL + '/api/invites.php',{
            user: auth.user
        });
        setInviteData(response.data);
        setEditItem(null);
        setName('');
        setSelectedProblems([]);
        setCommercial('0');
        window.scrollTo(0, 0);
    }

    return (
        <>
            {editOrder && <AdminEditTaskOrder inv={editOrder}></AdminEditTaskOrder>}
            {!editOrder &&
                <>
            {editItem != null ?
                <Container>
                    <h2>Редактируем</h2>
                    <Container className={'text-start'}>Укажите название ссылки</Container>
                    <Form.Control type={'text'} onChange={(e) => setName(e.target.value)}
                                  value={name}></Form.Control>
                    <Container className={'text-start'}>Укажите начало действия
                        приглашения</Container>
                    <Container className={'text-start my-2'}>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            timeInputLabel="День и Время:"
                            dateFormat="yyyy-MM-dd HH:mm:00"
                            showTimeInput
                        />
                    </Container>
                    <Container className={'text-start'} onClick={onNewInvite}>Укажите окончания действия
                        приглашения</Container>
                    <Container className={'text-start my-2'}>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            timeInputLabel="День и Время:"
                            dateFormat="yyyy-MM-dd HH:mm:00"
                            showTimeInput
                        />
                    </Container>
                    <Container>
                        <Form.Select onChange={(e) =>
                        {
                            if (e.target.value === '0')
                            {
                                return;
                            }

                            let sDate = new Date(startDate);

                            const values = [
                                [9,0],
                                [10,40],
                                [12,30],
                                [14,20],
                                [16,0],
                                [17,40],
                                [19,20],
                                [21,0]
                            ];
                            const currentValue = values[parseInt(e.target.value) - 1];

                            sDate.setHours(currentValue[0], currentValue[1], 0);
                            setStartDate(sDate);
                        }}>
                            <option value="0">Выберите начальную пару</option>
                            <option value="1">1. 09:00-10:30</option>
                            <option value="2">2. 10:40-12:10</option>
                            <option value="3">3. 12:30-14:00</option>
                            <option value="4">4. 14:20-15:50</option>
                            <option value="5">5. 16:00-17:30</option>
                            <option value="6">6. 17:40-19:10</option>
                            <option value="7">7. 19:20-20:50</option>
                            <option value="8">8. 21:00-22:00</option>
                        </Form.Select>
                    </Container>
                    <Container>
                        <Form.Select onChange={(e) =>
                        {
                            if (e.target.value === '0')
                            {
                                return;
                            }

                            let dDate = new Date(endDate);

                            const values = [
                                [10,30],
                                [12,10],
                                [14,0],
                                [15,50],
                                [17,30],
                                [19,10],
                                [20,50],
                                [22,0]
                            ];
                            const currentValue = values[parseInt(e.target.value) - 1];

                            dDate.setHours(currentValue[0] , currentValue[1], 0);
                            setEndDate(dDate);
                        }}>
                            <option value="0">Выберите конечную пару</option>
                            <option value="1">1. 09:00-10:30</option>
                            <option value="2">2. 10:40-12:10</option>
                            <option value="3">3. 12:30-14:00</option>
                            <option value="4">4. 14:20-15:50</option>
                            <option value="5">5. 16:00-17:30</option>
                            <option value="6">6. 17:40-19:10</option>
                            <option value="7">7. 19:20-20:50</option>
                            <option value="8">8. 21:00-22:00</option>
                        </Form.Select>
                    </Container>
                    {auth.user === 'admin' &&
                        <Container className={'text-start mt-5'}>
                            <Form.Check
                                defaultChecked={commercial != '0'}
                                type={'switch'}
                                id={`checkbox-commercial-edit`}
                                label={'Коммерция'}
                                onChange={(e) =>
                                {
                                    setCommercial(e.target.checked ? '1' : '0')
                                }}
                            />
                        </Container>
                    }
                    {commercial != '0' &&
                        <Container className={'mt-3 text-start'}>
                            <Container>
                                Задачи: <a href={'javascript:void(0);'}
                                           onClick={e => {
                                               const selProblems = [];
                                               problems.forEach(p => selProblems.push(p.id));
                                               setSelectedProblems(selProblems);
                                           }}>Выбрать все</a>
                            </Container>
                            {selectedProblems && problems.map(problem => <Container className={'mb-3'}>
                                <Form.Check
                                    defaultChecked={selectedProblems.find(id=>id==problem.id)}
                                    type={'checkbox'}
                                    label={problem.title}
                                    onClick={e => {
                                        if (e.target.checked && !selectedProblems.find(id=>id==problem.id)) {
                                            selectedProblems.push(problem.id);
                                            setSelectedProblems(selectedProblems);
                                        }
                                        else
                                        {
                                            setSelectedProblems(selectedProblems.filter(id=>id!=problem.id));
                                        }
                                    }}
                                />
                            </Container>)}
                        </Container>
                    }
                    <Button onClick={onEditInvite}>Сохранить</Button>
                </Container>
                :
                <Container>
                    <ul className={'text-start'}>
                        {inviteData.map(item =>
                            <li className={'my-5 ' + (item.disactive == 1 || new Date(item.end) - new Date() <= 0 ? 'bg-red' : 'bg-green')}>
                                <Container>
                                    {item.name.trim() === '' ?
                                        <b>Без имени</b>
                                        :
                                        <b>{item.name}</b>
                                    }
                                    &nbsp;
                                    {item.commercial != '0' && <i>(Коммерция)</i>}
                                </Container>
                                <Container>
                                    <Container>
                                        {item.disactive == 1 || new Date(item.end) - new Date() <= 0 ?
                                            <>{item.invite}</>
                                            :
                                            <a href={'javascript:void(0);'}
                                               onClick={() => toClipBoard(item.invite)}>{'https://psycase.ru/?' + item.invite}</a>
                                        }
                                    </Container>

                                    <Container>
                                        {new Date(item.end) - new Date() > 0 &&
                                            <>
                                                <>начало работы ссылки</>
                                                &nbsp;
                                                {
                                                    new Date(item.start).getDate() + '-' + (new Date(item.start).getMonth() + 1)+ '-' + new Date(item.start).getFullYear()
                                                    + ' ' +
                                                    new Date(item.start).getHours() + ':' + new Date(item.start).getMinutes()
                                                }
                                            </>
                                        }
                                    </Container>
                                    <Container>
                                        <>конец работы ссылки</>
                                        &nbsp;
                                        {
                                            new Date(item.end).getDate() + '-' + (new Date(item.end).getMonth() + 1) + '-' + new Date(item.end).getFullYear()
                                            + ' ' +
                                            new Date(item.end).getHours() + ':' + new Date(item.end).getMinutes()
                                        }
                                    </Container>

                                    {item.commercial != '0' &&
                                        <Container>Задачи:
                                            <ol>
                                                {item.problems.length === 0 ?
                                                    <li>Нет задач</li>
                                                    :
                                                    <>{item.problems.map(problem => <li>{problem.title}</li>)}</>
                                                }
                                            </ol>
                                        </Container>
                                    }

                                    <Container className={'row'}>
                                        <Container className={'col-5 text-start'}>
                                            {item.commercial == '0' &&
                                                <>
                                                    {item.userCount == 0 ?
                                                        <a className={'my-1'}
                                                           href={'javascript:void(0);'}
                                                           onClick={() => setEditOrder(item.invite)}>Очередность задач</a>
                                                        :
                                                        <>Пользователей зарегистрировалось: {item.userCount}</>
                                                    }
                                                </>
                                            }
                                        </Container>
                                        <Container className={'col-7 text-end'}>
                                            {new Date(item.end) - new Date() > 0 &&
                                                <>
                                                    {item.disactive == 1 ?
                                                        <>
                                                            <Button className={'btn-info mx-2'}
                                                                    onClick={() => onAct(item.invite)}>Включить</Button>
                                                        </>
                                                        :
                                                        <Button className={'btn-secondary mx-2'}
                                                                onClick={() => onDisact(item.invite)}>Отключить</Button>
                                                    }
                                                </>
                                            }
                                            <Button className={'mx-2 btn-warning'}
                                                    onClick={() => onStartEdit(item)}>Редактировать</Button>
                                            {!props.sub &&
                                                <Button className={'mx-2 btn-danger'}
                                                        onClick={() => onDelete(item.invite)}>Удалить</Button>
                                            }
                                        </Container>
                                    </Container>
                                </Container>
                            </li>
                        )}
                    </ul>
                    <Container className={'bg-light'}>
                        <h2>Создать новую ссылку</h2>
                        <Container className={'text-start'}>Укажите название ссылки</Container>
                        <Form.Control type={'text'} onChange={(e) => setName(e.target.value)}
                                      value={name}></Form.Control>
                        <Container className={'text-start'}>Укажите начало действия
                            приглашения</Container>
                        <Container className={'text-start my-2'}>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                timeInputLabel="День и Время:"
                                dateFormat="yyyy-MM-dd HH:mm:00"
                                showTimeInput
                            />
                        </Container>
                        <Container className={'text-start'}>Укажите окончания действия
                            приглашения</Container>
                        <Container className={'text-start my-2'}>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                timeInputLabel="День и Время:"
                                dateFormat="yyyy-MM-dd HH:mm:00"
                                showTimeInput
                            />
                        </Container>
                        <Container>
                            <Form.Select onChange={(e) =>
                            {
                                if (e.target.value === '0')
                                {
                                    return;
                                }

                                let sDate = new Date(startDate);

                                const values = [
                                    [9,0],
                                    [10,40],
                                    [12,30],
                                    [14,20],
                                    [16,0],
                                    [17,40],
                                    [19,20],
                                    [21,0]
                                ];
                                const currentValue = values[parseInt(e.target.value) - 1];

                                sDate.setHours(currentValue[0], currentValue[1], 0);
                                setStartDate(sDate);
                            }}>
                                <option value="0">Выберите начальную пару</option>
                                <option value="1">1. 09:00-10:30</option>
                                <option value="2">2. 10:40-12:10</option>
                                <option value="3">3. 12:30-14:00</option>
                                <option value="4">4. 14:20-15:50</option>
                                <option value="5">5. 16:00-17:30</option>
                                <option value="6">6. 17:40-19:10</option>
                                <option value="7">7. 19:20-20:50</option>
                                <option value="8">8. 21:00-22:00</option>
                            </Form.Select>
                        </Container>
                        <Container>
                            <Form.Select onChange={(e) =>
                            {
                                if (e.target.value === '0')
                                {
                                    return;
                                }

                                let dDate = new Date(endDate);

                                const values = [
                                    [10,30],
                                    [12,10],
                                    [14,0],
                                    [15,50],
                                    [17,30],
                                    [19,10],
                                    [20,50],
                                    [22,0]
                                ];
                                const currentValue = values[parseInt(e.target.value) - 1];

                                dDate.setHours(currentValue[0] , currentValue[1], 0);
                                setEndDate(dDate);
                            }}>
                                <option value="0">Выберите конечную пару</option>
                                <option value="1">1. 09:00-10:30</option>
                                <option value="2">2. 10:40-12:10</option>
                                <option value="3">3. 12:30-14:00</option>
                                <option value="4">4. 14:20-15:50</option>
                                <option value="5">5. 16:00-17:30</option>
                                <option value="6">6. 17:40-19:10</option>
                                <option value="7">7. 19:20-20:50</option>
                                <option value="8">8. 21:00-22:00</option>
                            </Form.Select>
                        </Container>
                        {auth.user === 'admin' &&
                            <Container className={'text-start mt-5'}>
                                <Form.Check
                                    type={'switch'}
                                    id={`checkbox-commercial-new`}
                                    label={'Коммерция'}
                                    onChange={(e) =>
                                    {
                                        setCommercial(e.target.checked ? '1' : '0')
                                    }}
                                />
                            </Container>
                        }
                        {commercial != '0' &&
                            <Container className={'mt-3 text-start'}>
                                <Container>
                                    Задачи: <a href={'javascript:void(0);'}
                                               onClick={e =>
                                               {
                                                   const selProblems = [];
                                                   problems.forEach(p => selProblems.push(p.id));
                                                   setSelectedProblems(selProblems);
                                               }}>Выбрать все</a>
                                </Container>
                                {selectedProblems && problems.map(problem => <Container className={'mb-3'}>
                                    <Form.Check
                                        type={'checkbox'}
                                        defaultChecked={selectedProblems.find(id => id == problem.id)}
                                        label={problem.title}
                                        onClick={e =>
                                        {
                                            if (e.target.checked && !selectedProblems.find(id => id == problem.id))
                                            {
                                                selectedProblems.push(problem.id);
                                                setSelectedProblems(selectedProblems);
                                            } else
                                            {
                                                setSelectedProblems(selectedProblems.filter(id => id != problem.id));
                                            }
                                        }}
                                    />
                                </Container>)}
                            </Container>
                        }
                        <Button onClick={onNewInvite}>Создать</Button>
                    </Container>
                </Container>
            }
                </>
            }
        </>
    );
}

export default AdminInvite
